import { GAME_ID_KEY, UUID_KEY } from 'constants/keys'
import { status } from 'constants/enums'
import { iPlayer, iPlayerCard } from '../types/game'

// TODO: чекнуть типы
export const jsonStringify = (message: any) => {
  return JSON.stringify(message)
}

export const jsonParse = (message: any) => {
  return JSON.parse(message)
}

export const createUuid = () => {
  return Math.random().toString().slice(2, 8)
}

export const generateUuid = (key: string) => {
  const uuid: string = createUuid()
  localStorage.setItem(key, uuid)
  return uuid
}

export const stateToString = (state: string) => {
  if (state === status.MAKING_WORD) {
    return 'Загадывает'
  }
  if (state === status.WAITING) {
    return 'Ждём игроков'
  }
}

export const logoutGame = () => {
  localStorage.removeItem(UUID_KEY)
  localStorage.removeItem(GAME_ID_KEY)
  window.location.reload()
  window.location.href = '/'
}

export const getPlayerName = (players: iPlayer[], id: number) => {
  return players?.filter((player) => player.id === id)?.[0]?.name || ''
}

export const checkIfUserVoted = (
  votes: iPlayerCard[],
  playerId: number | undefined
) => {
  const checked = votes?.filter(
    (playerCard) => playerCard.playerId === playerId
  )
  return checked ? checked.length > 0 : false
}
