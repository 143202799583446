import React, { useEffect, useState } from 'react'
import { Button, Input, Typography } from 'antd'
import { CHOOSE_CARD, MAKE_WORD } from 'constants/urls'
import { status } from 'constants/enums'
import { useWebSocket } from 'ws/WebSocketContext'
const { Title } = Typography

interface iActions {
  selectedCardId: string | null
}

const Actions = ({ selectedCardId }: iActions) => {
  const { game, sendMessage, currentPlayer, isAdmin, isPlayer } = useWebSocket()

  const [isChose, setIsChose] = useState<boolean>(false)

  const isChoiceMade =
    game?.players &&
    currentPlayer?.hand &&
    game.players.length > currentPlayer.hand.length

  const [word, setWord] = useState('')

  const onMakeWord = () => {
    const updated = {
      gameId: game?.id,
      playerId: currentPlayer?.id,
      cardId: selectedCardId,
      word,
    }
    sendMessage(MAKE_WORD, updated)
  }

  useEffect(() => {
    if (word && selectedCardId) {
      setIsChose(true)
    }

    return () => {
      setIsChose(false)
    }
  }, [word, selectedCardId])

  const onChoose = () => {
    const choice = {
      gameId: game?.id,
      playerId: currentPlayer?.id,
      cardId: selectedCardId,
    }
    sendMessage(CHOOSE_CARD, choice)
  }

  return (
    <>
      {isAdmin && game?.status === status.MAKING_WORD && (
        <>
          {/* <Title className="title" level={5}>
            Выберите карту, введите слово и нажмите на кнопку
          </Title> */}

         <div>
         <Input
            className="input"
            placeholder="Введите слово"
            onChange={(e) => setWord(e.target.value)}
          />

          <div className="mt-2">
            <Button
              className="button"
              type="primary"
              onClick={onMakeWord}
              disabled={!isChose}
            >
              Загадать слово
            </Button>
          </div>
         </div>
        </>
      )}
      {!isChoiceMade && isPlayer && game?.status === status.CHOOSING && (
        <>
          <Button className="button" type="primary" onClick={onChoose}>
            Сделать выбор
          </Button>
        </>
      )}
    </>
  )
}

export default Actions
