import React from "react"
import { Link } from "react-router-dom"
import logo from 'assets/artcard-logo.svg'

const Logo = () => {

    return (
        <div>
            <div className="logo-container">
            <Link
                to="/"
                className="logo"
                style={{
                     backgroundImage: `url(${logo})`,
             }}
            ></Link>

            </div>
        </div>
    )
}

export default Logo