import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography } from 'antd'
import { Button } from 'antd'
import PageTemplate from '../PageTemplate/PageTemplate'
import { ADD_AI_PLAYER, START_GAME } from 'constants/urls'
import { HAND } from 'constants/routes'
import Loader from '../Loader/Loader'
import Players from '../Players/Players'
import { useWebSocket } from 'ws/WebSocketContext'
import { createUuid } from 'helpers/helpers'

const { Title } = Typography

const WaitingPlayers = () => {
  const { game, sendMessage } = useWebSocket()
  const navigate = useNavigate()

  const onStart = () => {
    sendMessage(START_GAME, game?.id)
    navigate(HAND)
  }

  const onAddAiPlayer = () => {
    const aiUuid = createUuid()
    sendMessage(ADD_AI_PLAYER, { username: `ai_player_${aiUuid}`, uuid: aiUuid, gameId: game?.id })
  }

  return (
    <PageTemplate>
      <div className="flex">
        <Loader status="Ожидайте игроков" isFixed={false} />
        <Players />
        <Title className="title" level={4}>
          ID вашей игры: {game?.id}
        </Title>
        <Button className="button" type="primary" onClick={onStart}>
          Начать игру
        </Button>
        <Button className="button" type="primary" onClick={onAddAiPlayer}>
          Добавить AI-игрока
        </Button>
      </div>
    </PageTemplate>
  )
}

export default WaitingPlayers
