import React from 'react';
import { Carousel } from 'antd';

const Slider = ({ cards, initialSlide, onClose }) => {
  console.log('Cards received in Slider:', cards);
  cards.forEach(card => console.log(card?.link));
  return (
    <div
      onClick={onClose}
      className='ant-slider-custom'
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0,0,0,0.5)',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Carousel
        initialSlide={initialSlide}
        adaptiveHeight={true} // Адаптивная высота для разных размеров слайдов
        style={{ width: '60%', maxWidth: '1000px' }} // Ограничение ширины карусели
      >
        {cards.map((card, index) => (
          <div key={index} style={{ textAlign: 'center' }}>
            <img
              src={card.link}
              alt={`Card ${index}`}
              style={{ maxHeight: '80vh', maxWidth: '100%' }} // Ограничение размера изображений
              onClick={e => e.stopPropagation()} // Предотвращение закрытия при клике на изображение
            />
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default Slider;
