import React, { useEffect, useState } from 'react'
import { status } from 'constants/enums'
import { useWebSocket } from 'ws/WebSocketContext'
import styles from './Card.module.scss'
import { iCard, iPlayerCard } from '../../types/game'
import VoteInfo from 'components/Card/VoteInfo'

interface iCardPage {
  card: iPlayerCard
  isSelected?: boolean
  onClick?: () => void
  onDoubleClick?: () => void
  isVoting?: boolean
  votes?: number[]
  isLeaderCard?: boolean
}

const Card = ({
  card,
  isSelected,
  onClick,
  onDoubleClick,
  isVoting = false,
  votes,
  isLeaderCard,
}: iCardPage) => {
  const { game, isAdmin, currentPlayer } = useWebSocket()

  const [isCanChoose, setIsCanChoose] = useState(true)
  const cardClasses = `${styles.card} ${isSelected ? styles.card_selected : ''} ${
    !isCanChoose ? styles.no_vote : ''
  } ${isVoting && isLeaderCard ? styles.leaderCard : ''}`

  useEffect(() => {
    // если игрок - ведущий и сейчас голосование или выбор карты
    const isVotingOrChoosing =
      isAdmin &&
      (game?.status === status.VOTING || game?.status === status.CHOOSING)
    // если не голосование
    // и если у игрока карт меньше, чем игроков в игре
    // TODO
    const hm =
      currentPlayer?.hand &&
      game?.players &&
      currentPlayer.hand.length < game.players.length &&
      game?.status !== status.VOTING

    // state === voting
    // игрок не может голосовать за свою карту
    const voting = currentPlayer?.id === card.playerId

    if (isVotingOrChoosing || hm || voting) {
      setIsCanChoose(false)
    }

    return () => {
      setIsCanChoose(true)
    }
  }, [game, isAdmin, currentPlayer, card])

  return (
    <div
      key={card.id}
      className={cardClasses}
      onClick={() => isCanChoose && onClick && onClick()}
      onDoubleClick={() => isCanChoose && onDoubleClick && onDoubleClick()}
    >
      {isVoting && (
        <div className={styles.card__vote_info}>
          <VoteInfo votes={votes} />
        </div>
      )}
      <img className={`${styles.card__image}`} src={card.link} alt={card.id} />
    </div>
  )
}

export default Card
