import { Spin } from "antd";
import { status } from "constants/enums";
import { checkIfUserVoted } from "helpers/helpers";
import React from "react";
import { useWebSocket } from "ws/WebSocketContext";

const Instraction = () => {
    const { game, isPlayer, currentPlayer, isAdmin } = useWebSocket()

    const leader_makingWord_text : string = "Выберите карту, введите слово и нажмите на кнопку"
    const player_makingWord_text : string = "Ведущий загадывает слово. Ожидайте"
    const playersStillChoosing = game?.players?.filter(player => !game.roundState?.votes?.some(dto => dto.playerId === player.id)
    && player.id !== game.currentLeaderId
  )
      .map(it => it.name)

    const checkIfUserChose = () => {
        const checked = game?.roundState?.choices?.filter(
          (playerCard) => playerCard.playerId === currentPlayer?.id
        )
        return checked ? checked.length > 0 : false
      }      
      
      
    return (
       <div className="instruction_container">
         {game?.status && game?.status !== status.WAITING &&( <img style={{height: "20px", width: "20px"}} src="../../../info_icon.png"  />)}

          {game?.status === status.VOTING && isAdmin && (
              <>
                <div className="mb-2"><div>Идет голосование. Ожидаем игроков</div>
                {playersStillChoosing?.map(player => (
                    <div>● {player}</div>
                 ))}</div>
                <Spin />
              </>
          )}    

          {game?.status === status.MAKING_WORD && isAdmin && (
              <div className="mb-2">{leader_makingWord_text}</div>
          )}

          {game?.status === status.VOTING &&
            isPlayer &&
            !checkIfUserVoted(game?.roundState?.votes, currentPlayer?.id) && (
            <div className="mb-2">Проголосуйте за наиболее подходящую карту</div>
          )}

          {game?.status === status.MAKING_WORD && isPlayer && (
              <div className="mb-2">{player_makingWord_text}</div>
          )}

          {game?.status === status.CHOOSING && isAdmin && (
              <div className="mb-2">
                 <div>Ждите пока игроки выберут карты:</div>
                 {playersStillChoosing?.map(player => (
                    <div>● {player}</div>
                 ))}
             </div>
          )}

          {game?.status === status.CHOOSING && isPlayer && !checkIfUserChose() && (
              <div className="mb-2">
                Выберите карту, подходящую к загаданной ассоциации
              </div>
            )}
          </div>
    )
}

export default Instraction