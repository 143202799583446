import Word from "components/Hand/Word";
import React from "react";
import { useWebSocket } from "ws/WebSocketContext";

const GameInfoTop = () => {
    const { game, isPlayer, isAdmin, currentPlayer, error } = useWebSocket()
    
    const getLeaderName = () => {
        return game?.players?.filter(
          (player) => player.id === game.currentLeaderId
        )[0].name    
      }
    
    return (
        <div className="info_container">
          <div>
            <img style={{height: "24px", width: "24px"}} src="../../../flag_icon.png"  />
            <span>{getLeaderName() === currentPlayer?.name ?  getLeaderName() + " (вы)" : getLeaderName()}</span>
          </div>
          <Word />
          <div style={{display: "flex", justifyContent: 'center', alignItems: 'center', gap: "6px"}}>
            <img style={{height: "24px", width: "24px"}} src="../../../star_icon.png"  /> 
            <span>{currentPlayer?.score}</span>
          </div>
        </div>
    )
}

export default GameInfoTop