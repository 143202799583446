import React from 'react'
import bgImage from 'assets/bg-image.svg'
import { iChildren } from 'types/common'
import { useWebSocket } from 'ws/WebSocketContext'
import Logo from 'components/Logo/Logo'
import GameInfoTop from 'components/GameInfoTop/GameInfoTop'
import { status } from 'constants/enums'
import Instraction from 'components/Instruction/Instruction'

const PageTemplate = ({ children }: iChildren) => {
  const { game } = useWebSocket()
  const isGameInProcess = game?.status === status.CHOOSING || game?.status === status.VOTING || game?.status === status.MAKING_WORD;

  return (
    <div className="container">
      <div className="bg-container">
        <div
          className="bg-image"
          style={{
            backgroundImage: `url(${bgImage})`,
          }}
        />
      </div>
      {isGameInProcess ? <GameInfoTop /> : <Logo />}
      <Instraction />
      {children}
    </div>
  )
}

export default PageTemplate
