// TOPICS
export const TOPIC_GAME_STATUS = (id: string) => `/topic/games/status/${id}`
export const TOPIC_CURRENT_PLAYER = (id: string) =>
  `/topic/games/currentPlayer/${id}`
export const TOPIC_ERRORS = (id: string) =>
  `/topic/games/currentPlayer/errors/${id}`

// ENDPOINTS
export const START_GAME: string = '/app/games/start'
export const GET_GAME: string = '/app/games/getGame'
export const CREATE_GAME: string = '/app/games/create'
export const ADD_PLAYER: string = '/app/games/addPlayer'
export const ADD_AI_PLAYER: string = '/app/games/addAiPlayer'
export const MAKE_WORD: string = '/app/games/makeAWord'
export const CHOOSE_CARD: string = '/app/games/chooseCard'
export const VOTE: string = '/app/games/vote'
